import React from "react";
import about_s4_1 from "../Images/abc3.jpg";
import about_s4_2 from "../Images/abc5.jpg";
import about_s4_3 from "../Images/abc6.jpg";
import about_s4_4 from "../Images/zee.jpeg";
import about_s4_5 from "../Images/zeer.jpeg";
import about_s4_6 from "../Images/subhan.jpeg";
import about_s4_7 from "../Images/ah.jpeg";
import about_s4_8 from "../Images/gym.jpeg";
export const LifeAtKloud = () => {
  return (
    <>
      <div className="about-s4 mb-4">
        <h3 className="mt-4 text-center">Life at Technokloud</h3>
        <div className="row d-flex justify-content-center">
          <div className="col-11 col-md-10 mt-4">
            <div className="row d-flex justify-content-between">
              <div className="col-6 col-md p-2">
                <img className="img-fluid" src={about_s4_1} alt="" />
              </div>
              <div className="col-6 col-md p-2">
                <img className="img-fluid" src={about_s4_2} alt="" />
              </div>
              <div className="col-6 col-md p-2">
                <img className="img-fluid" src={about_s4_3} alt="" />
              </div>
              <div className="col-6 col-md p-2">
                <img className="img-fluid" src={about_s4_4} alt="" />
              </div>
            </div>
            <div className="row d-flex justify-content-between">
              <div className="col-6 col-md p-2">
                <img className="img-fluid" src={about_s4_5} alt="" />
              </div>
              <div className="col-6 col-md p-2">
                <img className="img-fluid" src={about_s4_6} alt="" />
              </div>
              <div className="col-6 col-md p-2">
                <img className="img-fluid" src={about_s4_7} alt="" />
              </div>
              <div className="col-6 col-md p-2">
                <img className="img-fluid" src={about_s4_8} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
