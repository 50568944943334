import React from 'react'
import section_5_1 from '../Images/section_5_1.png'
import section_5_2 from '../Images/section_5_2.png'
 const FeaturedProject = () => {
  return (<> 
  <div className='section-5 row d-flex justify-content-center '>
    <div className='col-10 mt-4 mb-4'>
      <h3 className='mb-4 text-center'>Featured Work</h3>
      <div className='row text-center'>
          <div className='col-12 col-md-6' onClick={()=> window.location.replace(`https://www.firewallforce.se/`)}>
              <img className='img-fluid mt-4 mb-4' style={{cursor: "pointer"}} src={section_5_1} alt="" />
          </div>
          <div className='col-12 col-md-6' onClick={()=> window.location.replace(`https://play.google.com/store/apps/details?id=com.myavvocatoapp2`)}>
              <img className='img-fluid mt-4 mb-4' style={{cursor: "pointer"}} src={section_5_2} alt="" />
          </div>
      </div>
    </div>
  </div>
  </>
  )
}

export default FeaturedProject
