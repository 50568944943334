import React from 'react'
import Section_1 from '../Images/section_1.png'

export const ChooseUs = () => {
  
    return (
        <>
            <div className='section-1 home row d-flex justify-content-center '>
                <div className='col-9 m-t-b'>
                    <div className='row d-flex justify-content-between '>
                        <div className='col-12 col-md-5 justify'>
                            <h4 className='black'>Why Choose Us?</h4>
                            <p className='mt-4 '>We are a full-service software house. Our clients ranges from public and private sectors including start-ups. What separates us from others is the flexibility of hiring us as a team of experts or individual developers. From the start, till the end, we have you covered. We provide our clients with successful solutions in UI/UX Design, Web Development and Mobile App development.</p>
                        </div>
                        <div className='col-5 d-none d-md-block'>
                            <img className='img-fluid' src={Section_1} width={300} alt="Logo" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
