import React from 'react'

export const Achievements = () => {
  return (<>
  <div className='section-4 row d-flex justify-content-center '>
        <div className='col-10 col-md-8 m-t-b p-2 section-4-1'>
          <div className='row d-flex align-items-center'>
            <div className='col-12 col-md mt-4 mb-4 p-2'>
              <h4>Our Company Achievements</h4>
            </div>
            <div className='col-12 col-md mt-4 mb-4 p-2'>
              <h2>37</h2>
              <h6>Satisfied Clients</h6>
            </div>
            <div className='col-12 col-md mt-4 mb-4 p-2'>
              <h2>120</h2>
              <h6>Projects Completed</h6>
            </div>
            <div className='col-12 col-md mt-4 mb-4 p-2'>
              <h2>1,268</h2>
              <h6>Support Hours</h6>
            </div>
            <div className='col-12 col-md mt-4 mb-4 p-2'>
              <h2>3,800</h2>
              <h6>Development Hours</h6>
            </div>
          </div>
        </div>
      </div>
  </>
  )
}
