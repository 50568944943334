import React,{useEffect, useState} from 'react'
import {
    BrowserRouter as Router,
    Link
  
  } from "react-router-dom";
  import "../CSS/component.css"

export const HomeHeader = () => {
    const [toggleMenu, setToggleMenu] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)


  const toggleNav = () => {
    setToggleMenu(!toggleMenu)
  }

  useEffect(() => {

    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', changeWidth)

    return () => {
        window.removeEventListener('resize', changeWidth)
    }

  }, [])
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (<>
    <header className='home-header'>
        <div className='row d-flex justify-content-around'>
          <div className='col-sm-12 col-md-10  mt-4 p-2 '>
            <div className='row d-flex align-items-center'>
              <h2 className='col-3 d-flex d-none d-sm-block justify-content-start' to="/">
                <Link className="nav-link" style={{color: 'white'}} to="/">TechnoKloud</Link>
              </h2>
              {(toggleMenu || screenWidth > 576) &&(
              <ul className="col list mt-0 p-0 nav justify-content-end">
               
                <li className="nav-item">
                  <Link className="nav-link bold active"  aria-current="page" to="/">HOME</Link>
                </li>
                <li className="nav-item">
                  <Link  className="nav-link bold" to="/about">ABOUT</Link>
                </li>
                <li className="nav-item">
                  <Link  className="nav-link bold" to="/services">SERVICES</Link>
                </li>
                <li className="nav-item">
                  <Link  className="nav-link bold" to="/contact">CONTACT</Link>
                </li>
              </ul>)}
              <button onClick={toggleNav} className="nav-btn">
                <i class="bi bi-list"></i>
              </button>
            </div>
          </div> 
        </div>
        <div className='row d-flex justify-content-center mt-4'>
          <div className='col-11 col-md-8'>
            <div className='row mt-4 '>
              <div className='col-11 col-md-8 sections'>
              <h1>Committed<br />to be creative</h1>
              <p className='mt-4'>We create beautiful experiences that drive successful businesses.</p>
              </div>
            </div>
          </div>
        </div>
      </header>
   </>
  )
}
