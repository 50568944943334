import React from 'react'

const NotFound = () => {
  return (
    <div style={{width:"100%", height:"40em"}}>
        <h2 style={{display:"flex",margin:"auto", justifyContent:"center"}}>Page Not Found</h2>
    </div>
  )
}


export default NotFound;