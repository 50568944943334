
import "../CSS/component.css"

import React, { useState, useEffect } from 'react'
import Contact_Menu from '../Contact/Contact_Menu';
import { HomeHeader } from './HomeHeader';
import FeaturedProject from './FeaturedProject';
import { Achievements } from './Achievements';
import { UserService } from './UserService';
import { SuccessStories } from './SuccessStories';
import { ChooseUs } from './ChooseUs';

function Home() {
  const [toggleMenu, setToggleMenu] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)


  const toggleNav = () => {
    setToggleMenu(!toggleMenu)
  }

  useEffect(() => {

    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', changeWidth)

    return () => {
      window.removeEventListener('resize', changeWidth)
    }

  }, [])
 
  return (
    <>
      <HomeHeader />


      {/* //// Section -1 ///////// */}
      <ChooseUs />


      {/* //// Section -2 ///////// */}
      <UserService />

      {/* //// Section -4 ///////// */}
      <Achievements />


      {/* //// Section -5 ///////// */}

      <FeaturedProject />

      {/* //// Section -6 ///////// */}

      <Contact_Menu />
      {/* //// Section -7 ///////// */}

      {/* <SuccessStories /> */}
    </>
  )
}
export default Home
