import React from 'react'
import section_2 from '../Images/section_2.png'

export const UserService = () => {
  return (<>
   <div className='section-2 row d-flex justify-content-center'>
        <div className='col-11 col-md-7 mt-4 mb-4'>
          <h3 className='text-center'>How we do?</h3>
          <p className='mb-4 text-center'>Once we join hands, this is what we will take you through from the start to a deliver product.</p>
          <img className='img-fluid mt-2 mb-4' style={{width:"100%"}} src={section_2}  alt="Logo" /> 
        </div>
        <div className='row section-2-1  mb-4  justify-content-center'>
          <div className='col-11 col-md-7' style={{marginLeft:"35px"}}>
            <div className='row '>
            <div className='col-12 col-md p-2'> 
              <div className='row'>
                <div className='col'>
                  <h6 className='bold' style={{color:"skyblue"}}>Meet the user</h6>
                  <ul className='p-0'>
                    <li>Demographics</li>
                    <li>Define user goals</li>
                    <li>Define user challenges</li>
                    <li>Defines metrics</li>
                  </ul>
                </div >
              </div>
            </div>
            <div className='col-12 col-md p-2'> 
              <div className='row'>
                <div className='col'>
                <h6 className='bold' style={{color:"#FF4500"}}>Be the user</h6>
                  <ul className='p-0'>
                    <li>User personas</li>
                    <li>User scenarios</li>
                    <li>User testing</li>
                    <li>User Experience</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-12 col-md p-2 ' > 
              <div className='row'>
                <div className='col'>
                <h6 className='bold' style={{color:"#FF8C00"}}>Make it simple</h6>
                  <ul className='p-0'>
                    <li>Wire-frames</li>
                    <li>Mood-board</li>
                    <li>Mock-ups</li>
                    <li>Style Guides</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-12 col-md p-2 '> 
              <div className='row'>
                <div className='col '>
                <h6 className='bold' style={{color:"#20B2AA"}}>Data don’t lie</h6>
                  <ul className='p-0'>
                    <li>Usability testings</li>
                    <li>Review metrics</li>
                    <li>Track usage</li>
                    <li>Inform next iteration</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-12 col-md p-2 '> 
              <div className='row'>
                <div className='col'>
                <h6 className='bold' style={{color:"#4682B4"}}>Stick to the design</h6>
                  <ul className='p-0'>
                    <li>Usability testings</li>
                    <li>Review metrics</li>
                    <li>Track usage</li>
                    <li>Inform next iteration</li>
                  </ul>
                </div>
              </div>
            </div>
            </div>
          </div>
            
          </div>
      </div>
    </>
  )
}
