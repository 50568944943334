import React, { useState } from "react";
// import * as Yup from "yup";
// import { Formik, Form, Field, ErrorMessage } from "formik";
//import Form from 'react-bootstrap/Form';
import section_6_1 from "../Images/section_6_1.png";
import section_6_2 from "../Images/section_6_2.png";
import axios from "axios";

export default function Contact_Menu() {
  const [loading, setLoading] = useState(false);
  const [formValue, setFormValue] = useState({
    name: "",
    surname: "",
    emailaddress: "",
    cellphone: "",
    message: "",
  });

  async function handleSubmit(e) {
    e.preventDefault();
    const postData = {
      name: formValue.name,
      surname: formValue.surname,
      emailaddress: formValue.emailaddress,
      cellphone: formValue.cellphone,
      message: formValue.message,
    };
    console.log("formValue===>12", formValue);
    try {
      setLoading(true);
      const res = await axios.post(
        "https://technokloud.onrender.com/technokloud/sendEmail",
        postData
      );
      if (res) {
        console.log(res.data);
        setFormValue({
          name: "",
          surname: "",
          emailaddress: "",
          cellphone: "",
          message: "",
        });
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const onChanges = (e) => {
    console.log(e.target.value);
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  return (
    <div className="section-6 row d-flex justify-content-center justify">
      <div className="col-10 p-4 mt-4 mb-4">
        <div className="row d-flex justify-content-between">
          <div className="col-12 col-md-6 section-6-1">
            <h4>Contact Us</h4>

            <form onSubmit={handleSubmit}>
              <input
                required
                type="text"
                name="name"
                value={formValue.name}
                onChange={onChanges}
                className="form-control mt-4"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Your Name"
              />
              <input
                required
                type="text"
                name="surname"
                value={formValue.surname}
                onChange={onChanges}
                className="form-control mt-4"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Your Surname"
              />
              <input
                required
                type="email"
                name="emailaddress"
                value={formValue.emailaddress}
                onChange={onChanges}
                className="form-control mt-4"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Your Email"
              />

              <input
                required
                type="number"
                name="cellphone"
                value={formValue.cellphone}
                onChange={onChanges}
                className="form-control mt-4"
                id="exampleInputEmail1"
                placeholder="Your Phone Number"
              />

              <textarea
                required
                className="mt-4"
                value={formValue.message}
                onChange={onChanges}
                name="message"
                placeholder="Your message"
              ></textarea>

              <button
                className="btn btn-success mt-4 mb-4 p-3 bold"
                type="submit"
                value="submit"
              >
                {loading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only m-3">Loading...</span>
                  </>
                ) : (
                  "SUBMIT REQUEST"
                )}
              </button>
            </form>
          </div>
          <div className="col-5 section-6-2">
            <h4 className="d-none d-md-block">Request a Quote</h4>
            <p className="d-none d-md-block">
              Would you like to start a project with us? We are just one click
              away from hearing you, Don't wait.{" "}
            </p>
            <p className="mt-4 mb-4 d-none d-md-block">
              Email: <span className="bold">infotechno@gmail.com</span>
            </p>
            <h5>Reviewed on</h5>
            <img
              className="img-fluid mt-4"
              width={150}
              src={section_6_1}
              alt=""
            />{" "}
            <br />
            <img
              className="img-fluid mt-4"
              width={100}
              src={section_6_2}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}
