import React from 'react'
import services_s1_1 from '../Images/services_s1_1.jpg'
import services_s1_2 from '../Images/services_s1_2.jpg'
import services_s1_3 from '../Images/services_s1_3.jpg'

 const SoftDev = () => {
  return (
    <div className='services-s1 '>
    <div className='row'>
      <h3 className='mt-4 text-center'>Software Development</h3>
      <div className='row d-flex justify-content-center'>
        <div className='col-11 col-md-7 mt-2'>
         <p className='text-center'>We provide software development, maintenance, support, deployment and implementation services as well as Web Development and Mobile Apps for both Android and iOS.</p>
        </div> 
      </div>
    </div>
    <div className='row mt-4 p-4 d-flex justify-content-center'>
      <div className='col-11 col-md-10'>
        <div className='row'>
        <div className="col-12 col-md services-s1-1 m-2 p-4  d-flex flex-column bd-highlight ">
          <div className=" mb-auto text-center p-2 bd-highlight">
            <img className='img-fluid mb-4 ' width={200} src={services_s1_1} alt="" />
          </div>
          <div className="p-2 bd-highlight">
            <h5 className='justify'>Web Development</h5>
          </div>
          <div className="p-2 bd-highlight">
            <p className='justify'>Having been into the business for over a decade, We love to convert your ideas into reality & full filling your needs by offering responsive .</p>
          </div>
        </div>

        <div className="col-12 col-md services-s1-1 m-2 p-4 d-flex flex-column bd-highlight ">
          <div className="mb-auto text-center p-2 bd-highlight">
            <img className='img-fluid mb-4' width={200} src={services_s1_2} alt="" />
          </div>
          <div className="p-2 bd-highlight">
            <h5 className='justify'>Mobile Development</h5>
          </div>
          <div className="p-2 bd-highlight">
            <p className='justify'>We provide 360 solutions ranging from communication, social networking and entertainment mobile apps for Android and iOS.</p>
          </div>
        </div>
        
        <div className="col-12 col-md services-s1-1 m-2 p-4 d-flex flex-column bd-highlight ">
          <div className="mb-auto text-center p-2 bd-highlight">
            <img className='img-fluid mb-4' width={200} src={services_s1_3} alt="" />
          </div>
          <div className="p-2 bd-highlight">
            <h5 className='justify'>WooCommerce & Shopify</h5>
          </div>
          <div className="p-2 bd-highlight">
            <p className='justify'>We offer unmatched eCommerce services to our users that help businesses expand & deliver value to their customers.</p>
          </div>
        </div>
        </div>
      </div>  
    </div>
  </div>
  )
}

export default SoftDev
